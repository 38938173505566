import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import sql from "../common/sql";
import { FiseHotelEntity } from "../../../backend/src/shared/entity"; // Update path as necessary

const SearchHotel = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  // Fetch last 10 entries initially, then fetch based on search term
  const {
    data: model,
    isLoading,
    refetch,
  } = useQuery<FiseHotelEntity[]>({
    queryKey: ["FiseHotelFullTextSearch", searchTerm],
    queryFn: () => {
      if (searchTerm) {
        return sql.FiseHotelFullTextSearch({ SearchTerm: searchTerm });
      } else {
        return sql.FiseHotelListLastTen(); // Assuming you have this endpoint/function to fetch the last 10 records
      }
    },
    enabled: true, // This will auto-run on mount and whenever searchTerm changes
  });

  useEffect(() => {
    refetch(); // This will trigger a refetch when searchTerm changes.
  }, [searchTerm, refetch]);

  const formatDate = (date: Date | null): string => {
    return date
      ? new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(new Date(date))
      : "";
  };

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Utilizator: {sessionStorage.getItem("UserName")}</h1>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search..."
          aria-label="Search"
          aria-describedby="button-addon2"
        />
      </div>
      {isLoading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Data:</th>
              <th>NrAuto</th>
              <th>Descriere</th>
            </tr>
          </thead>
          <tbody>
            {model?.map((item, index) => (
              <tr
                key={item.Id}
                onClick={() => navigate(`/search/${item.Id}`)}
                style={index === 0 ? { backgroundColor: "yellow" } : {}}
              >
                <td>{formatDate(item.DataAdaugare)}</td>
                <td>{item.NrAuto}</td>
                <td>{item.Descriere}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SearchHotel;
